<template>
  <!--start the bussiness  Modal -->
  <div class="modal fade" id="createBussiness" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text" id="exampleModalLabel">
            <strong style="color: #00364f">Bussiness Create</strong>
          </h4>
          <button @click="valueNullForm()" type="button" class="btn-close" data-bs-dismiss="modal"
            aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="
            businessVal == 'false' ? createBussinessSave() : updateBussinessSave()
            ">
            <div class="row">
              
              <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Personal Pan <small style="color: #f21000">*</small></label>
                <!-- v-if="panDisabled == 'false'" -->
                <input required v-if="personalPanDisabled == 'false'" type="tel" @change="personalPanveryfied()"
                  v-model="personalPanNo" class="form-control" placeholder="Pan" style="text-transform: uppercase" />
                <input v-if="personalPanDisabled == 'true'" type="tel" v-model="personalPanNo" class="form-control"
                  placeholder="Pan" style="text-transform: uppercase" disabled />


               
              </div>

              <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding: 5px 5px 5px 5px">
                <small v-if="veryfied == 'invalid'" class="text-danger">X <i>Please Enter Valid Pan</i></small>
                <small v-if="veryfied == 'false'"></small>

                <label>Bussiness Pan <small style="color: #f21000">*</small></label>
                <input v-if="panDisabled == 'false'" type="tel" @change="businessPanVeryfied" v-model="panNo"
                  class="form-control" placeholder="Pan" style="text-transform: uppercase" />
                <input v-if="panDisabled == 'true'" type="tel" @change="businessPanVeryfied"
                  v-model="bussiness_form.panNo" class="form-control" placeholder="Pan" style="text-transform: uppercase"
                  disabled />
                <small style="color: red" v-if="bussiness_form.errors">
                  <span v-if="bussiness_form.errors.error"><span v-if="bussiness_form.errors.error.panNo">{{
                    bussiness_form.errors.error.panNo[0]
                  }}</span></span></small>


              </div>

              <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Business Type <span style="color:#f21000">*</span></label>
                <select required v-model="bussiness_form.business_type_id" class="form-select"
                  aria-label="Default select example" name="businessType">
                  <option selected disabled>Bussiness Types...</option>
                  <option :value="type.id" v-for="(type, index) in businesstype" :key="index">
                    {{ type.type }}
                  </option>
                </select>



                <small style="color: red" v-if="bussiness_form.errors">
                  <span v-if="bussiness_form.errors.error"><span v-if="bussiness_form.errors.error.business_type_id">{{
                    bussiness_form.errors.error.business_type_id[0]
                  }}</span></span></small>


              </div>
              <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>Business Name <span style="color:#f21000">*</span></label>

                <input type="text " v-model="bussiness_form.name" class="form-control" placeholder="Business Name"
                  required />
                  <small style="color: red" v-if="bussiness_form.errors">
                  <span v-if="bussiness_form.errors.error"><span v-if="bussiness_form.errors.error.name">{{
                    bussiness_form.errors.error.name[0]
                  }}</span></span></small>
              </div>

              <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>CIN </label>
                <input maxlength="21" minlength="21" type="tel" v-model="bussiness_form.cinNo" class="form-control"
                  placeholder="CinNo" />

                  <small style="color: red" v-if="bussiness_form.errors">
                  <span v-if="bussiness_form.errors.error"><span v-if="bussiness_form.errors.error.cinNo">{{
                    bussiness_form.errors.error.cinNo[0]
                  }}</span></span></small>
              </div>

              <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>GST No. </label>
                <input  type="tel" maxlength="15" minlength="15" v-model="bussiness_form.gstinNo"
                  class="form-control" placeholder="GstIn" />
                  <small style="color: red" v-if="bussiness_form.errors">
                  <span v-if="bussiness_form.errors.error"><span v-if="bussiness_form.errors.error.gstinNo">{{
                    bussiness_form.errors.error.gstinNo[0]
                  }}</span></span></small>
              </div>

              <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>TAN</label>
                <input type="tel" maxlength="10" minlength="10" v-model="bussiness_form.tanNo" class="form-control"
                  placeholder="Tan No" />
              </div>
              <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding: 5px 5px 5px 5px">
                <label for="email">Email <span style="color:#f21000">*</span></label>
                <input type="email" class="form-control" v-model="bussiness_form.email" placeholder="Email Or UserId"
                  required />
                <small style="color: red" v-if="bussiness_form.errors">
                  <span v-if="bussiness_form.errors.error"><span v-if="bussiness_form.errors.error.email">{{
                    bussiness_form.errors.error.email[0]
                  }}</span></span></small>
              </div>
              <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding: 5px 5px 5px 5px">
                <label for="phone">Phone <span style="color:#f21000">*</span></label>
                <input type="tel"    @keydown="accountNoVall " maxlength="10" minlength="10" v-model="bussiness_form.phone" class="form-control"
                  placeholder="phone" style="text-transform: uppercase" required />
                <span style="color: #f21000" id="demophoneval"></span>
                <small style="color: red" v-if="bussiness_form.errors">
                  <span v-if="bussiness_form.errors.error"><span v-if="bussiness_form.errors.error.phone">{{
                    bussiness_form.errors.error.phone[0]
                  }}</span></span></small>
              </div>
              <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>State <span style="color:#f21000">*</span></label>
                <select required v-model="bussiness_form.state_id" class="form-select"
                  aria-label="Default select example">
                  <option selected disabled>Select...</option>
                  <option :value="state.id" v-for="(state, index) in states" :key="index">
                    {{ state.name }}
                  </option>
                </select>
              </div>
              <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding: 5px 5px 5px 5px">
                <label>City <span style="color:#f21000">*</span></label>
                <input required type="text " v-model="bussiness_form.city" class="form-control" placeholder="City"
                  style="text-transform: uppercase" />
              </div>
              <div class="col-xl-6 col-md-6 col-lg-6 col-12" style="padding: 5px 5px 5px 5px">

                <label>Pin <span style="color:#f21000">*</span></label>

                <input required     @keydown="accountNoVall " type="tel" v-model="bussiness_form.pin" maxlength="6" minlength="6" class="form-control"
                  placeholder="Pin" style="text-transform: uppercase" />
                <span style="color: #f21000" id="BUSpinCode"></span>
                <small style="color: red" v-if="bussiness_form.errors">
                  <span v-if="bussiness_form.errors.error"><span v-if="bussiness_form.errors.error.pin">{{
                    bussiness_form.errors.error.pin[0]
                  }}</span></span></small>



              </div>
              <div class="col-xl-12 col-md-12 col-lg-12 col-12" style="padding: 5px 5px 5px 5px">
                <label>Address <span style="color:#f21000">*</span></label>
                <!-- <input type="text" class="form-control" v-model="bussiness_form.address" placeholder="Address" required /> -->
                <textarea v-model="bussiness_form.address" class="form-control" rows="3"  placeholder="Address line 1 " required></textarea>
          
             
                <small style="color: red" v-if="bussiness_form.errors">
                  <span v-if="bussiness_form.errors.error"><span v-if="bussiness_form.errors.error.address">{{
                    bussiness_form.errors.error.address[0]
                  }}</span></span></small>
              </div>
              <div class="col-xl-12 col-md-12 col-lg-12 col-12" style="padding: 5px 5px 5px 5px">
                <label for="Address">Address 2</label>
                <!-- <input type="text " class="form-control" v-model="bussiness_form.address2" placeholder="Address" /> -->
             
             <textarea v-model="bussiness_form.address2" class="form-control" rows="3"  placeholder="Address line 2"></textarea>
              </div>
             
            </div>
            <div class="modal-footer">
              <button @click="valueNullForm()" type="button" class="btn btn-sm text-white"
                style="background-color: #f21300" data-bs-dismiss="modal">
                Close
              </button>
              <button type="submit" class="btn btn-sm text-white" style="background-color: #00364f">
                <strong>Confirm</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end of the create bussiness modal  -->

  <div v-if="loading == true">
          <Spinner />
        </div>
</template>

<script type="javascript">
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import jQuery from "jquery";
let $ = jQuery
export default {
  name: 'BusinessComponent',
  components: {
    Spinner,
  },
  data() {
    return {
      personalPanNo: '',
      gstinVal: "false",
      demophoneval: "false",
      bussinessName: "false",
      BUSpinCode: "false",
      adminCity: "false",
      adminAddress: "false",

      panNo: '',
      loading: false,
      client_id: null,
      states: [],
      clients: [],
      edit: 'false',


      businessAll: [],
      bussiness_form: {
        business_type_id: null,
        client_id: null,
        name: '',
        email: '',
        phone: '',
        panNo: '',
        tanNo: '',
        gstinNo: '',
        cinNo: '',
        address: '',
        address2: '',
        state_id: null,
        city: '',
        pin: '',
        logo: '',
        panPhoto: '',
        gstinPhoto: '',
        cinPhoto: '',
        tanPhoto: '',
        errors: {}
      },


      businessVal: 'false',
      businesstype: [],
      panDisabled: 'false',
      businessInformation: {},
      businessDataClient: {},

      // panDisabled: 'false',
      // record: {},
      veryfied: 'false',
      personalPanDisabled: 'false',
      // exist: 'false',
    }

  },
  methods: {
    valueNullForm() {

      this.personalPanNo = ""
      this.personalPanDisabled = 'false'

      this.demophoneval = "false";
      $("#demophoneval").html("<small></small>");

      this.submitStatus = "false";
      $("#demoAltphoneval").html("<small></small>");


      this.BUSpinCode = "false";
      $("#BUSpinCode").html("<small></small>");

      this.gstinVal = "false";
      $("#gstinVal").html("<small></small>");


      this.bussinessName = "false";
      $("#bussinessName").html("<small></small>");


      this.adminAddress = "false";
      $("#adminAddress").html("<small></small>");
      this.adminCity = "false";
      $("#adminCity").html("<small></small>");
      this.businessAddress = "false";
      $("#businessAddress").html("<small></small>");

      this.bussinessCity = "false";
      $("#bussinessCity").html("<small></small>");

      this.submitStatus = "false";
      $("#scretKeyLenth").html("<small></small>");
      this.panDisabled = 'false'
      this.panNo = ''
      this.bussiness_form = {}
      this.bussiness_form.errors = {}
    },

    lenthheker(event, type) {
      var data = event.target.value;
      if (type == "demophoneval") {
        if (data.length != 10) {
          console.log(data.length);
          this.demophoneval = "true";
          $("#demophoneval").html("<small>Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          console.log(data.length);
          this.demophoneval = "false";
          $("#demophoneval").html("<small></small>");
        }
      }
      if (type == "alt") {
        if (data.length != 10) {
          console.log(data.length);
          this.submitStatus = "true";
          $("#demoAltphoneval").html("<small>Alt Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          console.log(data.length);
          this.submitStatus = "false";
          $("#demoAltphoneval").html("<small></small>");
        }
      }

      if (type == "BUSpinCode") {

        if (data.length != 6) {
          console.log(data.length);
          this.BUSpinCode = "true";
          $("#BUSpinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          console.log(data.length);
          this.BUSpinCode = "false";
          $("#BUSpinCode").html("<small></small>");
        }
      }

      if (type == "gstinVal") {
        if (data.length != 15) {
          console.log(data.length);
          this.gstinVal = "true";
          $("#gstinVal").html("<small>GSTIN 15 Digit Required</small>");
        }
        if (data.length == 15) {
          console.log(data.length);
          this.gstinVal = "false";
          $("#gstinVal").html("<small></small>");
        }
      }

      if (type == "bussinessName") {
        if (data.length < 3) {
          console.log(data.length);
          this.bussinessName = "true";
          $("#bussinessName").html("<small>Required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.bussinessName = "false";
          $("#bussinessName").html("<small></small>");
        }
      }

      if (type == "adminAddress") {
        if (data.length < 3) {
          console.log(data.length);
          this.adminAddress = "true";
          $("#adminAddress").html("<small>Address required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.adminAddress = "false";
          $("#adminAddress").html("<small></small>");
        }
      }
      if (type == "adminCity") {

        if (data.length < 3) {
          console.log(data.length);
          this.adminCity = "true";
          $("#adminCity").html("<small>City required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.adminCity = "false";
          $("#adminCity").html("<small></small>");
        }
      }

      if (type == "businessAddress") {
        if (data.length < 3) {
          console.log(data.length);
          this.businessAddress = "true";
          $("#businessAddress").html("<small>Address required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.businessAddress = "false";
          $("#businessAddress").html("<small></small>");
        }
      }

      if (type == "bussinessCity") {
        if (data.length < 3) {
          console.log(data.length);
          this.bussinessCity = "true";
          $("#bussinessCity").html("<small>City required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.bussinessCity = "false";
          $("#bussinessCity").html("<small></small>");
        }
      }

      if (type == "scretKeyLenth") {
        if (data.length < 10) {
          console.log(data.length);
          this.submitStatus = "true";
          $("#scretKeyLenth").html(
            "<small>Secret Key required minimum 10 letters</small>"
          );
        }
        if (data.length >= 10) {
          console.log(data.length);
          this.submitStatus = "false";
          $("#scretKeyLenth").html("<small></small>");
        }
      }
    },
    valueAssignBussiness(allbusiness) {
      this.panNo = allbusiness.business.panNo
      this.bussiness_form.panNo = allbusiness.business.panNo
      this.bussiness_form = allbusiness.business
      this.panDisabled = 'true'
      this.businessVal = 'true'

    },
    // loadBussiness() {
    //     this.$axios
    //         .get(`retailer/businessclient?client_id=${this.$route.params.id}`, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
    //         .then(res => {
    //             this.businessAll = res.data.data.data

    //         })
    // },
    loadBussinessType() {
      this.loading = true
      this.$axios
        .get(`retailer/businesstype`, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
        .then(res => {
          // console.log(res.data.data.data)
          this.businesstype = res.data.data.data
        })
        .finally(() => (this.loading = false))
    },
    updateBussinessSave() {
    
        this.loading = true
        this.$axios
          .put(`retailer/business/${this.bussiness_form.id}`, this.bussiness_form, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
          .then(res => {
            res.data
            // console.log(res.data)
         
            toast.success(" Business Updated Succesfully", {
          autoClose: 1000,
        });

            this.bussiness_form = {}
            $("#createBussiness").modal("hide");
            this.panDisabled = 'false'
            this.veryfied = 'false'
            this.businessVal = 'false'
            this.valueNullForm()
            // this.loadBussiness()
          })
          .finally(() => (this.loading = false))
          .catch((error) => {

this.bussiness_form.errors = error.response.data

});

 

    },
    createBussinessSave() {



        this.bussiness_form.client_id = localStorage.getItem("client_id")
        
        this.loading = true

        this.$axios
          .post(`retailer/business`, this.bussiness_form, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
          .then(res => {
            console.log(res.data)
          
            
            toast.success(" Business Created Succesfully", {
          autoClose: 1000,
        });



            this.bussiness_form = {}
            this.bussiness_form.errors = {}
            $("#createBussiness").modal("hide");
            this.panDisabled = 'false'
            this.veryfied = 'false'
            this.businessVal = 'false'
            // this.loadBussiness()

            this.valueNullForm()
          })
          .finally(() => (this.loading = false))
          .catch((error) => {

            this.bussiness_form.errors = error.response.data
         
          });




      

    },

    deleteBussiness(id) {
      this.loading = true
      this.$axios
        .delete(`retailer/business/${id}`, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
        .then(res => {
          console.log(res.data)
   

          toast.success(" Business Deleted Succesfully", {
          autoClose: 1000,
        });
          // this.loadBussiness()

        })
        .finally(() => (this.loading = false))
    },
    personalPanveryfied() {
      this.loading = true
      this.$axios
        .get(`retailer/client?pan=${this.personalPanNo}`, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
        .then(res => {
          console.log(res.data)

          if (res.data.data.data[0]) {
            this.personalPanDisabled = 'true'

            localStorage.setItem("client_id", res.data.data.data[0].id);
            toast.success("Personal veryfied Succesfully", {
              autoClose: 1000,
            });
          } else {
            this.$swal.fire({
              icon: "error",
              title: "<h5>This Client Is Not Found Please Create First.</h5>",
              showConfirmButton: true,
            });
          }




        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          this.$swal.fire({
            position: "top-center",
            icon: "error",
            title: "<small>" + error.response.data.error + "</small>",
            showConfirmButton: true,
            confirmButtonColor: "#00364f",
          });
        });
    },
    businessPanVeryfied() {

      this.bussiness_form = {}
      this.loading = true
      this.$axios
        .get(`retailer/client?pan=${this.panNo}`, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
        .then(res => {
          this.record = res.data.data.data[0]
          if (res.data.data.data[0]) {

            this.bussiness_form.phone = this.record.phone
            this.bussiness_form.address = this.record.address
            this.bussiness_form.address2 = this.record.address2
            this.bussiness_form.email = this.record.email
            this.bussiness_form.state_id = this.record.state_id
            this.bussiness_form.city = this.record.city
            this.bussiness_form.pin = this.record.pin
            console.log(this.record)
            this.bussiness_form.client_id = this.record.id
            console.log(this.bussiness_form.client_id)
            this.bussiness_form.panNo = this.panNo

            for (var i = 0; i < this.record.business_clients.length; i++) {
              this.bussiness_form = this.record.business_clients[i].business
            }

            if (this.bussiness_form.id) {
              this.businessVal = 'true'
            }
            else {
              this.businessVal = 'false'
            }
            this.veryfied = 'false'
            this.panDisabled = 'true'
            this.veryfied = 'false'

          } else {
            this.loading = true
            this.$axios
              .post('retailer/verifypan', { 'panNo': this.panNo }, { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
              .then(res => {
                console.log(res.data.data)
                if (res.data.data.data.status == 'VALID') {
                  console.log('-----Verrifiyed--- in else--')



                  toast.success(" Pan Verrifiyed  Succesfully.", {
                    autoClose: 1000,
                  });

                  this.panDisabled = 'true'
                  this.veryfied = 'false'
                  this.bussiness_form.panNo = this.panNo
                  if (res.data.data.data.full_name) {
                    this.bussiness_form.name = res.data.data.data.full_name
                  }
                } else {
                  console.log('-----invalid pan--')
                  this.veryfied = 'invalid'
                  this.exist = 'false'
                }
              })
              .finally(() => (this.loading = false))
              .catch((error) => {
                this.$swal.fire({
                  position: "top-center",
                  icon: "error",
                  title: "<small>" + error.response.data.error + "</small>",
                  showConfirmButton: true,
                  confirmButtonColor: "#00364f",
                });
              });


          }


        })

        .finally(() => (this.loading = false))

      // this.panNo = ''
    },
    accountNoVall(e) {
  const inputValue = e.key;

  // Allowing only Backspace key
  if (inputValue === 'Backspace' || inputValue === 'Delete') {
    // Perform your specific action for the Backspace key
    console.log('Backspace key pressed!');
  } else {
    // Blocking other keys, including Delete, non-word characters, and alphabets
    if (/^\W$/.test(inputValue) || /[a-zA-Z]/.test(inputValue)) {
      this.error = true;
      e.preventDefault();
    } else {
      this.error = false;
    }
  }
},
    loadState() {
      this.$axios
        .get('retailer/state?all=all', { headers: { Authorization: 'Bearer ' + localStorage.accessToken } })
        .then(res => {
          this.states = res.data.data
          //console.log(this.states)
        })
    },
  },
  created() {
    this.loadBussinessType()
    this.loadState()
  }
}
</script>
